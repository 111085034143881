/**
 * This file has the code for desktop education menu
 */
import { Link as RoutingLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import featuredImage1 from "../../assets/media/mega-menu-media/education-featured-1.png";
import featuredImage2 from "../../assets/media/mega-menu-media/education-featured-2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const allEducationList = ["Basic Education","Advanced Education","Education Category 3","Education Category 4"];
const consumerExperience = ["Diamond Cuts","White Series","Ring Designs","Ring Sizing Guide","After-Sales Policy","Our Process","Customization & Bespoke process","Timeline","Packaging"];
const EducationMenu=()=>{
    return (
        <Container className="menuTabContainer">
        <Grid container className="insideTabContainer">
          <Grid item md={3}>
          <Typography variant="subtitle1" className="shopByTitles">All Education <FontAwesomeIcon icon={faAngleRight} /></Typography>
          {allEducationList.map((category,index) => (
              <Typography variant="subtitle1" className="shopByItems" key={index}>{category}</Typography>
          ))}
          <Typography variant="subtitle1" className="eduTitles">All Blog <FontAwesomeIcon icon={faAngleRight} /></Typography>
          <Typography variant="subtitle1" className="eduTitles1">All Press <FontAwesomeIcon icon={faAngleRight} /></Typography>
          <RoutingLink to={"/master-crafter"} className="linkComponent">
          <Typography variant="subtitle1" className="eduTitles1">Miyabi Master Crafter <FontAwesomeIcon icon={faAngleRight} /></Typography>
          </RoutingLink>
          </Grid>
          <Grid item md={3}>
          <Typography variant="subtitle1" className="shopByTitles">Consumer Experience <FontAwesomeIcon icon={faAngleRight} /></Typography>
          {consumerExperience.map((category, index) => (
              <Typography variant="subtitle1" className="shopByItems" key={index}>{category}</Typography>
          ))}
          </Grid>
          <Grid item md={6} className="menuRightSideContentTwo">
            <Typography variant="subtitle1" className="featureTitle">Featured</Typography>
            <Stack className="educationContainer" direction="row">
                <img src={featuredImage1} className="shopByEducationImages"/>
                <Box className="educationBox">
                    <Typography className="paraDiamond" variant="subtitle1">Featured Description</Typography>
                    <Link className="educationLink" href="#">Learn More <FontAwesomeIcon icon={faAngleRight} /></Link>
                </Box>
            </Stack>
            <Stack className="educationContainer" direction="row">
                <img src={featuredImage2}className="shopByEducationImages"/>
                <Box className="educationBox">
                    <Typography className="paraDiamond" variant="subtitle1">Featured Description</Typography>
                    <Link className="educationLink" href="#">Learn More <FontAwesomeIcon icon={faAngleRight} /></Link>
                </Box>
            </Stack>
          </Grid>
        </Grid>
       </Container>
    );
}
export default EducationMenu;