/**
 * This file has the code for education menu
 */
import { Link as RoutingLink } from "react-router-dom";
import featuredImage1 from "../../assets/media/mega-menu-media/education-featured-1.png";
import featuredImage2 from "../../assets/media/mega-menu-media/education-featured-2.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { closeIcon, leftArrowIcon } from "../../assets/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
const allEducationList = [
  "Basic Education",
  "Advanced Education",
  "Education Category 3",
  "Education Category 4",
];
const consumerExperience = [
  "Diamond Cuts",
  "White Series",
  "Ring Designs",
  "Ring Sizing Guide",
  "After-Sales Policy",
  "Our Process",
  "Customization & Bespoke process",
  "Timeline",
  "Packaging",
];
const MobileEducationMenu = ({ setCurrentmenu, setToggleMenu }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <Stack className="jpSandwichSubMenu"
            direction="row"
            justifyContent="space-between">
            <Typography className="jpSandwichSubMenuTitle">
              <img src={leftArrowIcon} onClick={() => setCurrentmenu(null)} />
              EDUCATION
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <img className="jpSandwichSubMenuCancel" src={closeIcon} onClick={() => setToggleMenu(false)} />
        </Grid>
      </Grid>
      <Divider />
      <Accordion defaultExpanded className="jpHeaderAccordion">
        <AccordionSummary  className="shopByDiamondTitleOne" expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
          ALL EDUCATION
        </AccordionSummary>
        <AccordionDetails>
          {allEducationList.map((category, index) => (
            <Typography key={index}>
              <Link className="shopByItemsMobile" underline="none" variant="subtitle1">{category}</Link>
            </Typography>
          ))}
        </AccordionDetails>
      </Accordion>
      <Typography className="eduSubMenu" variant="h4">All Blog</Typography>
      <Typography className="eduSubMenu" variant="h4">All Press</Typography>
      <RoutingLink to={"/master-crafter"} className="linkComponent">
      <Typography className="eduLastSubMenu" variant="h4">Miyabi Master Crafter</Typography>
      </RoutingLink>
      <Accordion defaultExpanded className="jpHeaderAccordion">
        <AccordionSummary className="shopByDiamondTitleOne" expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
          CONSUMER EXPERIENCE
        </AccordionSummary>
        <AccordionDetails>
          {consumerExperience.map((category, index) => (
            <Typography key={index}>
              <Link className="shopByItemsMobile" underline="none" variant="subtitle1">{category}</Link>
            </Typography>
          ))}
        </AccordionDetails>
      </Accordion>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography className="featureTitle" variant="subtitle1">Featured</Typography>
        </Grid>
      </Grid>  
        <Grid className="collectionWhiteSeriesRow" container spacing={2}>
        <Grid item xs={4}>
          <img src={featuredImage1} className="mobileMenuContentImage" />
        </Grid>
        <Grid item xs={8} className="collectionColumn">
          <Typography variant="subtitle1">Featured Description</Typography>
        </Grid>
        </Grid>
        <Grid className="collectionWhiteSeriesRow" container spacing={2}>
        <Grid item xs={4}>
        <img src={featuredImage2} className="mobileMenuContentImage" />
        </Grid>
        <Grid item xs={8} className="collectionColumn">
          <Typography variant="subtitle1">Featured Description</Typography>
        </Grid>
        </Grid>
       
    </>
  );
};
export default MobileEducationMenu;
