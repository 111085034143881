/**
 * This component has the structure of mobile diamond stepper
 */
import { useState } from "react";
// for routing
import { Link as RoutingLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { diamondImage } from "../assets/diamond-details-media";
import "../assets/css/components.css";
const MobileProcessSteps = ({ pageName, data = null }) => {
  // selected step
  const [activeStep, setActiveStep] = useState(0);
  // open popover
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, open) => {
    open ? setAnchorEl(anchorEl ? null : event.currentTarget) : setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  // console.log(open);
  const handleClose = () => {
    setAnchorEl(null);
  };
  // steps default data
  let steps = [
    {
      text: "Select Your Diamond",
      linkTo: "/choose-your-diamond",
      openPopover: false
    },
    {
      text: "Select Your Setting",
      linkTo: "/start-with-setting",
      openPopover: false
    },
    {
      text: "Complete Your Ring",
      linkTo: null,
      openPopover: false
    },
  ];
  // console.log(data);
  // if directly visited start with setting page then swap step[0] with step[1]
  if (pageName === "start-with-setting" && !data) {
    let temp = steps[0];
    steps[0] = steps[1];
    steps[1] = temp;
  }
  // if diamond data is selected
  if (
    (pageName === "start-with-setting" && data) ||
    (pageName === "diamond-details" && data)
  ) {
    steps[0] = {
      text : "Diamond Selected",
      // text: data.carat,
      icon: diamondImage,
      linkTo: null,
      openPopover: true
    };
    steps[1].linkTo = null;
  }
  return (
    <Box className="mobileStepperBox marginTopThirtyFivepx">
      <Stepper
        alternativeLabel
        className="mobileStepper"
        id="mobile-stepper"
        activeStep={activeStep}
      >
        {steps.map((obj, index) => (
          <Step
            key={obj.text}
            className={`${
              index <= activeStep ? "activeStepMobile" : "inactiveStepMobile"
            } mobileStep`}
            onClick={(e) => {
              handleClick(e, obj.openPopover);
            }}
          >
            {obj.linkTo === null ? (
              <StepLabel className="mobileStepLabel">{obj.text}</StepLabel>
            ) : (
              <RoutingLink to={obj.linkTo} className="linkComponent">
                <StepLabel className="mobileStepLabel">{obj.text}</StepLabel>
              </RoutingLink>
            )}
            {/* Popover to show after clicking on the steps */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              className="jpStepperPopoverMobile"
            >
              <Card className="jpStpperPopoverCard">
                <Grid container className="jpStepperPopoverContainer">
                  <Grid item xs={3} className="jpStepperPopoverImageGrid">
                    <CardMedia
                      component="img"
                      image={diamondImage}
                      className="jpStepperCardImage"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    className="jpStepperPopoverCardRightSideContent"
                  >
                    <Typography className="jpStepperPriceText">
                      {"SGD5,200"}
                    </Typography>
                    <Box className="jpStepperPopoverButtonsBox">
                      <RoutingLink
                        to="/diamond-details"
                        state={{ ...data, visitedFrom: "start-with-setting" }}
                        className="linkComponent"
                      >
                        <Button className="black-filled fullWidthButton">View</Button>
                      </RoutingLink>
                      <RoutingLink
                        to="/choose-your-diamond"
                        state={{ ...data, visitedFrom: "start-with-setting" }}
                        className="linkComponent"
                      >
                        <Button className="black-filled fullWidthButton">Change</Button>
                      </RoutingLink>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Popover>
          </Step>
        ))}
      </Stepper>
      <Divider className="mobileStepperDivider" />
    </Box>
  );
};
export default MobileProcessSteps;
