/**
 * This file has the diamond step process stepper for desktop
 */
import { useState, useEffect } from "react";
// for routing
import { Link as RoutingLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import {
  cydStepperDiamondImage,
  cydStepperSettingImage,
  cydStepperRingImage,
} from "../assets/cyd-media.js";
import { diamondImage } from "../assets/diamond-details-media";
import "../assets/css/components.css";
const ProcessSteps = ({data = null, pageName, }) => {
  const [activeStep, setActiveStep] = useState(0);
  // Update activeStep based on pageName and data
  useEffect(() => {
    if (pageName === "choose-your-diamond") {
      setActiveStep(0);
    } else if (pageName === "start-with-setting") {
      setActiveStep(data ? 1 : 0);
    } else if (pageName === "diamond-details") {
      setActiveStep(data ? 1 : 0);
    } else {
      setActiveStep(steps.length - 1); // Default to the last step
    }
  }, [pageName, data]);
  // steps default data
  let steps = [
    {
      text: "Select Your Diamond",
      link: "Browse Diamonds",
      icon: cydStepperDiamondImage,
      linkTo : "choose-your-diamond"
    },
    {
      text: "Select Your Setting",
      link: "Browse Setting",
      icon: cydStepperSettingImage,
      linkTo : "start-with-setting"
    },
    { text: "Complete Your Ring", icon: cydStepperRingImage },
  ];
  // if diamond data is selected
  if((pageName === "start-with-setting" && data) || (pageName === "diamond-details" && data)){
    steps[0] = {
      text : data.carat + " " + data.color + " " + data.clarity + " " + "GCAL" + " " + data.cut + " " + data.shape,
      link: ["View", "Change"],
      icon: diamondImage
    };
  }
  // if directly visited start with setting page then swap step[0] with step[1]
  if(pageName === "start-with-setting" && !data){
    let temp = steps[0];
    steps[0] = steps[1];
    steps[1] = temp;
  }
  const handleViewLink = () =>{
    console.log("Clicked on View link");
  }
  return (
    <Box className="desktopStepperBox marginTopThirtyFivepx">
      <Stepper
        activeStep={activeStep}
        className="desktopStepper"
        id="desktop-stepper"
      >
        {steps.map((label, index) => {
          return (
            <Step
              key={index}
              className={`${
                index <= activeStep
                  ? "activeStepDesktop"
                  : "inactiveStepDesktop"
              } desktopStep ${index<activeStep ? "selectedStep" : ""}`}
            >
              <StepLabel className="desktopStepLabel">
                <Box className="desktopStepContentBox">
                  <Typography className={`${index < activeStep ? "desktopStepLabelContainingData" : "desktopStepContentText"}`}>
                    {label.text}
                  </Typography>
                  {label.link ? (
                    typeof label.link === "string" ? (
                      pageName === label.linkTo ? (
                        <Link className="desktopStepContentLink">
                        {label.link}
                      </Link>
                      ):(
                        <RoutingLink key={"browseLink"+index} to={"/"+label.linkTo}  className="desktopStepContentLink">
                        {label.link}
                      </RoutingLink>
                      )
                    ) : (
                      <Stack direction="row" className="desktopStepLinksStack">
                        <RoutingLink to="/diamond-details" state={{...data, visitedFrom : "start-with-setting"}} className="desktopStepContentLink">
                          {label.link[0]}
                        </RoutingLink>
                        <Typography> | </Typography>
                        <RoutingLink to="/choose-your-diamond" state={{...data, visitedFrom : "start-with-setting"}} className="desktopStepContentLink">
                          {label.link[1]}
                        </RoutingLink>
                      </Stack>
                    )
                  ) : (
                    ""
                  )}
                </Box>
                <img src={label.icon} className={`${typeof label.link === "string" || !label.link ? "desktopStepIcon" : "desktopStepSelectedImage"}`} />
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Divider className="desktopStepperDivider" />
    </Box>
  );
}
export default ProcessSteps;